<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import mailSideBar from "@/components/email-components/mailSideBar";
import Toolbar from "@/components/email-components/toolbar";
import axios from "axios";

const axios2 = axios.create({
    baseURL: 'https://dev-live.rydelinx.com/api/admin/email/',
    headers: {
      'x-api-key': 'Wx8Cc7rEDJXTcyPfzM65h5q0AKDjLXqb'
    }
  })
/**
 * Email-read component
 */
export default {
  page: {
    title: "Read Email",
    meta: [{ name: "description" }]
  },
  components: {
    mailSideBar,
    Layout,
    PageHeader,
    Toolbar,
    ckeditor: CKEditor.component
  },
  data() {
    return {
      newMessage: null,
      title: "Read Email",
      items: [
        {
          text: "Email",
          href: "/email/inbox"
        },
        {
          text: this.$route.params.text,
          href: this.$route.query.href
        },
        {
          text: "Read Email",
          active: true
        }
      ],
      replyModal: false,
      editor: ClassicEditor,
      editorData: null,
      subject: null,
      toArray: [],
    };
  },

  computed:{
    email() {
        if(this.$route.params.text !== 'Sent Mail'){
          return this.$store.getters['emails/supportMailGetter'](this.$route.params.id)
        }
        else{
          return this.$store.getters['emails/sentMailGetter'](this.$route.params.id)

        }
    
    }
  },

  watch:{
    '$route.params.id'(newVal,oldVal){
      console.log(oldVal)
      if(this.$route.path.includes('reademail')){
        this.items[1].text = this.$route.params.text
        if(this.$route.params.text !== 'Sent Mail' ){
        this.$store.dispatch('emails/markMailRead',this.$route.params.id)
      }
      }
      
    }
  },

  mounted(){
    if(this.$route.params.text === 'Sent Email'){
      this.$store.dispatch('emails/getSentMails')
    }else{
      this.$store.dispatch('emails/getsupportMails')
    }
  },
  methods: {
    showModal() {
      this.replyModal = true
      document.getElementById('toInput').value = this.email.email

    },
    sendReply() {
      axios2.post('send-reply/' + this.email._id, { subject: this.subject, message: this.editorData }, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
        }
      }).then(() => {
        this.$refs.replyModal.hide()
        this.subject = null
        this.editorData = null
        this.$bvToast.toast('Mail Sent', {
          variant: 'success',
          solid: true
        })

      }).catch((err) => {
        console.log(err)
      })
    },

    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },

    texttoHtml(text) {
      return text.replace(/&lt;/g, '<')
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <mailSideBar />
        <div class="email-rightbar mb-3">
          <div class="card">
            <div class="btn-toolbar p-3" role="toolbar">
              <Toolbar :email="email" :href="$route.query.href" />
            </div>
           
          
            <div class="card-body">
              <div class="media mb-4 d-flex flex-row">
                <div class="media-body">
                  <h5 class="font-size-14 my-1" v-if="email.name">From: {{ email.name }}</h5>
                  <h5 class="font-size-14 my-1" v-if="email.toArray">Email: {{ email.toArray[0].email_address }}</h5>
                  <small class="text-muted" v-if="email.email">{{ email.email }}</small>
                </div>
                <div>
                  <p>{{ convertDate(email.createdAt) }} {{ convertTime(email.createdAt) }}</p>
                </div>
              </div>

              <h4 class="mt-0 font-size-16">{{ email.subject }}</h4>

              <p v-if="email.name && $route.params.text !== 'Replies'">Dear Admin,</p>
              <p v-if="$route.params.text === 'Replies'">Dear {{ email.name }}</p>
              <p v-html="texttoHtml(email.message)"></p>
              <p>Sincerly,</p>
              <hr />

              <!--              <div class="row">-->
              <!--                <div class="col-xl-2 col-6">-->
              <!--                  <div class="card">-->
              <!--                    <img-->
              <!--                      class="card-img-top img-fluid"-->
              <!--                      src="@/assets/images/small/img-3.jpg"-->
              <!--                      alt="Card image cap"-->
              <!--                    />-->
              <!--                    <div class="py-2 text-center">-->
              <!--                      <a href class="font-weight-medium">Download</a>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-xl-2 col-6">-->
              <!--                  <div class="card">-->
              <!--                    <img-->
              <!--                      class="card-img-top img-fluid"-->
              <!--                      src="@/assets/images/small/img-4.jpg"-->
              <!--                      alt="Card image cap"-->
              <!--                    />-->
              <!--                    <div class="py-2 text-center">-->
              <!--                      <a href class="font-weight-medium">Download</a>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

              <a href="javascript: void(0);" class="btn btn-secondary waves-effect mt-4"
                v-if="$route.params.text === 'Inbox' || $route.params.text === 'Starred'" v-on:click="showModal()">
                <i class="mdi mdi-reply"></i> Reply
              </a>
            </div>
          </div>
        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>

    <b-modal v-model="replyModal" ref="replyModal" title="Reply" centered>
      <form>
        <div class="form-group">
          <input type="email" id="toInput" :value=email.email class="form-control" readonly>
        </div>

        <div class="form-group">
          <input type="text" class="form-control" placeholder="Subject" v-model="subject" />
        </div>
        <div class="form-group">
          <ckeditor v-model="editorData" :editor="editor"></ckeditor>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="replyModal = false">Close</b-button>
        <b-button variant="primary" v-on:click="sendReply()">
          Send
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
  </Layout>
</template>
