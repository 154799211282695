<template>
  <div>
    <div class="btn-group mr-2 mb-2 mb-sm-0">
  
      <button type="button" class="btn btn-primary" v-on:click="deleteMail()">
        <i class="far fa-trash-alt"></i>
      </button>

    </div>

    <button v-if="this.email.starred === false" type="button" class="btn btn-primary" v-on:click="star()">
      <i class="far fa-star"></i>
    </button>

    <button v-if="this.email.starred === true" type="button" class="btn btn-primary" v-on:click="unStar()">
      <i class="fas fa-star"></i>
    </button>
  </div>
</template>

<script>


export default {
  name: 'Toolbar',
  props: ['email', 'href'],

  methods: {
    async deleteMail() {
      let ids = [this.email._id]
      await this.$store.dispatch('emails/deletesupportMail', ids).then((res) => {
        console.log('del', res)
        this.$router.push(this.href)
      })
    },

    star() {
      let ids = [this.email._id]
      this.$store.dispatch('emails/starMail', ids)


    },
    unStar() {
      let ids = [this.email._id]
      this.$store.dispatch('emails/unStarMail', ids)

    }
  }
}
</script>